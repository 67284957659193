<template>
  <section class="login-form">
    <v-form ref="loginForm" v-model="valid" autocomplete dense @submit.prevent="onLogin">
      <img
        src="../../public/logo.png"
        alt="Северная Венеция"
        style="width: 100%; margin-bottom: 20px"
      />
      <v-text-field-ext  v-model="login" autocomplete label="Логин" required maxlength="50" />
      <v-text-field-ext
        v-model="password"
        autocomplete
        label="Пароль"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="passwordRules"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
      />
      <div class="center">
        <v-btn color="primary" type="submit" width="150px" :loading="loading">
          Войти
        </v-btn>
      </div>
    </v-form>
  </section>
</template>

<script>
import Auth from '@/api/auth.js';
export default {
  data: () => ({
    valid: false,
    login: '',
    password: '',
    show: false,
    passwordRules: [
      (v) => !!v || 'Обязательное поле',
      (v) => v.length >= 4 || 'Минимум 6 символов',
    ],
    rememberMe: false,
    loading: false,
  }),
  methods: {
    async onLogin() {
      if (this.valid) {
        this.loading = true;
        try {
          await Auth.login(this.login, this.password);
        } catch (error) {
          this.showAlert(error);
        } finally {
          this.loading = false;
        }
        if (Auth.authorized) {
          this.$router.push('/orders');
        }
      }
    },
  },
};
</script>
<style>
.login-form {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 22rem;
  margin: 10% auto;
  padding: 25px 25px;
  border-radius: 5px;
  box-shadow: 0 0.9rem 1.5rem rgba(18, 38, 63, 0.15);
  border: 1px solid #aaa;
  background-color: #fff;
}
.login-form__label {
  margin: 0 !important;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
